import React, { useEffect, useState } from "react";
import '@google/model-viewer';

import Pistol from '../../assets/3dObjects/Pistol.glb'
import Pistol2 from '../../assets/3dObjects/Pistol_02.glb'
import Pistol3 from '../../assets/3dObjects/Pistol_03.glb'
import { useDispatch, useSelector } from "react-redux";
import CreateModal from "../../components/CreateModal/CreateModal";
import { socket } from "../../socket";
import { deleteRoom, joinRoom,leaveRoom} from "../../redux/actions/room";
import { useNavigate } from "react-router-dom";
import getDate from "../../helpers/getDate";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const StartPage = () => {
    const { t } = useTranslation();
    const { auth } = useSelector((state) => state);
    const { roomsList } = useSelector((state) => state.room);
    const [createModal,setCreateModal] = useState(false);
    const dispatch=useDispatch();
    const navigate=useNavigate();

    const createClick=(e)=>{
        setCreateModal(true);
    }

    const deleteRoomClick=(e)=>{
        let data={}
        data.id=e.currentTarget.id;
        dispatch(deleteRoom(data))
    }
    const joinRoomClick=(e)=>{
        let data={}
        data.room_id=e.currentTarget.id;
        dispatch(joinRoom(data))
    }
    const leaveRoomClick=(e)=>{
        let data={}
        data.room_id=e.currentTarget.id;
        dispatch(leaveRoom(data))
    }
    const routeRoomClick=(e)=>{
        navigate('/room/'+e.currentTarget.id);
    }


    const checkAnswer=(e)=>{
        if(e.room_id){
            navigate('/room/'+e.room_id)
        }
    }

    useEffect(() => {
        if(!roomsList?.length){
            socket.emit('getRooms', {});
        }
    }, []);
    useEffect(()=>{
        socket.on('roomJoinAnswer',checkAnswer);
    })

    const rooms = useMemo(() => {return roomsList}, [roomsList]);



    return (<>
     {auth.isLoggedIn && createModal &&
        <CreateModal setOpen={setCreateModal}></CreateModal>
     }
        {auth.isLoggedIn && <div>   
            <button className="prButton prButtonMain" onClick={createClick}>Створити кімнату</button>
        </div>}
    <div>
        <div> {t("room_list")} {rooms?.length}</div>
        <div style={{marginBottom:'40px'}}>

            {rooms?.length>0 && rooms.map((el,index)=>{

                return(
                    <div key={index} className="prFlexRow itemsList" style={{marginBottom:'20px'}} id={el.id}>
                        <div>{index+1}</div>
                        <div className="prflexColumn">
                            {el.totalUsers}/{el.maxUsers}
                            <span>{t("users")}</span>
                        </div>
                        <div className="prflexColumn">
                            {el.balanceCount}$
                        <span>{t("balance_count")}</span>
                        </div>
                        <div className="prflexColumn">
                            {el.currentBalance}$
                        <span>{t("current_balance")}</span>
                        </div>
                        <div className="prflexColumn">
                            {el.timeForTurn}
                        <span>{t("time_for_turn")}</span>
                        </div>
                        <div className="prflexColumn">
                            {el.isDefaultPistol?t("yes"):t("no")}
                        <span>{t("is_default_pistol")}</span>
                        </div>
                        <div className="prflexColumn">
                            {el.chatRequest?t("yes"):t("no")}
                        <span>{t("chat_request")}</span>
                        </div>
                        <div className="prflexColumn">
                            {el.updatedAt && getDate(el.updatedAt.replace('T',' '),'hh:mm dd/mmmm/yyyy','-')}
                        <span>{t("updated_at")}</span>
                        </div>
                        <div>
                            <button id={el.id} className="prButton prButtonMain" onClick={routeRoomClick}>{t("watch")} </button>
                            {!rooms[0].isRoomUser && auth?.user?.user && !el.isRoomUser && el.createdBy!=auth?.user?.user.id && <button id={el.id} className="prButton prButtonMain" style={{margin:'0 20px'}} onClick={joinRoomClick}>{t("join")}</button>}
                            {auth?.user?.user && (el.isRoomUser && el.createdBy!=auth?.user?.user.id) &&  <button id={el.id} className="prButton prButtonMain" style={{margin:'0 20px'}} onClick={leaveRoomClick}>{t("leave")} </button>}
                            {el.createdBy==auth?.user?.user.id && <button id={el.id} className="prButton prButtonMain" onClick={deleteRoomClick}>{t("delete")} </button>}
                        </div>
                    </div>
                )
            })}
        </div>

    </div>

        <div className="prFlexRow" style={{width:'100%',justifyContent:'center',gap:'15px'}}>
            <model-viewer
                src={Pistol}
                alt="Pistol 3D Model"
                auto-rotate
                camera-controls
                ar
                ios-src={Pistol}
                style={{ width: '300px', height: '300px' }}
            ></model-viewer>
            <model-viewer
                src={Pistol2}
                alt="Pistol 3D Model"
                auto-rotate
                camera-controls
                ar
                ios-src={Pistol2}
                style={{ width: '300px', height: '300px' }}
            ></model-viewer>
            <model-viewer
                src={Pistol3}
                alt="Pistol 3D Model"
                auto-rotate
                camera-controls
                ar
                ios-src={Pistol3}
                style={{ width: '300px', height: '300px' }}
            ></model-viewer>
        </div>
        </>
    );
};

export default StartPage