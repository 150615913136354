import React from 'react';
import { useSelector } from 'react-redux';
import {Navigate, Route,Routes, Redirect} from 'react-router-dom';
import Page404 from './pages/404Page/Page404';
import AuthPage from './pages/AuthPage/AuthPage';
import RegistrationPage from './pages/RegistrationPage/RegistrationPage';
import RoomPage from './pages/RoomPage/RoomPage';
import StartPage from './pages/StartPage/StartPage';

const UseRoutes = (props) => {
    const {auth} = useSelector(state=>state);

    return(
        <Routes>
            {!auth.isLoggedIn && <Route path='/login'  element={<AuthPage/>}/>}
            {!auth.isLoggedIn &&<Route path='/registration'  element={<RegistrationPage/>}/>}
            {<Route path="/room/:id" element={<RoomPage/>}/>}
            <Route path='/page404' element={<Page404/>}/>
            <Route path='*' element={<Page404/>}/>
            {!auth.isLoggedIn && <Route path="/" element={<StartPage/>}/>}
            {auth.isLoggedIn && <Route path="/" element={<StartPage/>}/>}
        </Routes>
    
    )
}


export default UseRoutes;