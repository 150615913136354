
import { refreshToken } from './actions/auth';

function jwt({ dispatch, getState }) {
    return (next) => (action) => {
        if (typeof action === 'function') {
            const localData = JSON.parse(localStorage.getItem('userData'))
            if (localData && localData.accessToken) {
                let token = JSON.parse(atob(localData.accessToken.split('.')[1]));
                let tokenExpiration = token.exp * 1000 - Date.now();
                if (tokenExpiration && tokenExpiration<0) {
                    if (!getState().auth.freshTokenPromise) {
                        return refreshToken(dispatch,localData.refreshToken).then(() => next(action));
                    } else {
                        return getState().auth.freshTokenPromise.then(() => next(action));
                    }
                }
            }
        }
        return next(action);
    };
}

export default jwt;
