import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getRoomDetail } from '../../redux/actions/room';
import { socket } from '../../socket';

const RoomPage =()=>{
    const dispatch=useDispatch();
    const navigate = useNavigate();
    const[ready,setReady]=useState(false); 
    const { room } = useSelector((state) => state.currentRoom);


    const id= useParams().id

    const initialFunc=()=>{
        dispatch(getRoomDetail({id:id})).then(res=>{

        }).catch(e=>{
            console.log(e);
        })
        setReady(true);
    };


    useEffect(() => {
        if(!ready){
            initialFunc();
        }
        
    }, [ready]);
    return(
    <div className='prFlexRow' style={{width:'100%'}}>
        <div>
            <div>
                roomInfo
            </div>
            <div>
                roomTable
            </div>
        </div>
        <div>
            <div>
                roomActions
            </div>
            <div>
                Chat
            </div>
        </div>

    
    </div>)

}

export default RoomPage;