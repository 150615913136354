import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SEND_CODE,
  DONE_REFRESHING_TOKEN,
  REFRESHING_TOKEN

} from "./types";

import AuthService from "../services/auth.service";


export const register = (form) => (dispatch) => {
  return AuthService.register(form).then(
    (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: REGISTER_FAIL,
          payload: response.data,
        });
      return response.data;
      }else{
        dispatch({
          type: REGISTER_SUCCESS,
          payload: response.data,
        });
        return  response.data;
      }
      
    },
    (error) => {
      dispatch({
        type: REGISTER_FAIL,
      });
      return Promise.reject();
    }
  );
};

export const login = (body) => (dispatch) => {
   
  return AuthService.login(body).then(
    (data) => {
      if( !data.error ){
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });
      }
      else{
        dispatch({
          type: LOGIN_FAIL,
        });
      }
      return data;
    },
    (error) => {
      dispatch({
        type: LOGIN_FAIL,
      });
      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};
export const registerCode = (body) => (dispatch) => {
  return AuthService.registerCode(body).then(
    (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: SEND_CODE,
        });
      return response.data;
      }else{
        dispatch({
          type:  SEND_CODE,
        });
        return  response.data;
      }
    },
    (error) => {
      dispatch({
        type:  SEND_CODE,
      });
      return Promise.reject();
    }
  );
};



export const refreshToken = (dispatch,token)=>{
  let freshTokenPromise = AuthService.refresh(token)
  .then(t => {
       
      dispatch({
          type: DONE_REFRESHING_TOKEN
      });
      return (t && t.token) ? Promise.resolve(t.token) : logout();
  })
  .catch(e => {
       
      console.log('error refreshing token', e);

      dispatch({
          type: DONE_REFRESHING_TOKEN
      });
      logout();
      return Promise.reject(e);
  });
  dispatch({
    type: REFRESHING_TOKEN,
    freshTokenPromise
  });
  return freshTokenPromise;
}
