import { useMemo } from "react";

import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import { ReactComponent as FB } from "../../assets/images/fb.svg";
import { ReactComponent as FBAuth } from "../../assets/images/fb-auth.svg";
import { ReactComponent as Insta } from "../../assets/images/insta.svg";
import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";
import { ReactComponent as TikTok } from "../../assets/images/tiktok.svg";
import { ReactComponent as EyeClosed } from "../../assets/images/ph_eye-closed.svg";
import { ReactComponent as EyeOpen } from "../../assets/images/eye-open.svg";
import { ReactComponent as Google } from "../../assets/images/google.svg";
import { ReactComponent as Send } from "../../assets/images/send.svg";




export const Icon = (props) => {
  const icon = useMemo(() => {
    switch (props.type) {
     
      case "logo":
        return <Logo />;
      case "fb":
        return <FB />;   
      case "google":
        return <Google />;   
      case "fbAuth":
        return <FBAuth />;      
      case "insta":
        return <Insta />;      
      case "telegram":
        return <Telegram />;
      case "tikTok":
        return <TikTok />;
      case "eyeClosed":
        return <EyeClosed />;  
      case "eyeOpen":
        return <EyeOpen />;  
      case "send":
        return <Send />;  
      default:
        return null;
    }
  }, [props.type]);

  if (props.link)
    return (
      <a className="prSvgIcon" href={props.link} target="_blank">
        {icon}
      </a>
    );

  if (props.onClick || props.isButton)
    return (
      <button onClick={props.onClick} id={props.id} className={props.className || null}>
        {icon}
        {props.children ?? null}
      </button>
    );

  return icon;
};
