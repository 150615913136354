export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SEND_CODE = "SEND_CODE";
export const DONE_REFRESHING_TOKEN = "DONE_REFRESHING_TOKEN";
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";





export const CREATEROOM_SUCCESS = "CREATEROOM_SUCCESS";
export const CREATEROOM_FAIL = "CREATEROOM_FAIL";
export const GETROOMLIST_SUCCESS = "GETROOMLIST_SUCCESS";
export const GETROOMLIST_FAIL = "GETROOMLIST_FAIL";
export const GETROOMDETAIL_SUCCESS = "GETROOMDETAIL_SUCCESS";
export const GETROOMDETAIL_FAIL = "GETROOMDETAIL_FAIL";
export const SETROOMLIST_SUCCESS = "SETROOMLIST_SUCCESS";
export const DELETEROOM_FAIL = "DELETEROOM_FAIL";
export const DELETEROOM_SUCCESS = "DELETEROOM_SUCCESS";
export const JOINROOM_SUCCESS = "JOINROOM_SUCCESS";
export const JOINROOM_FAIL = "JOINROOM_FAIL";
export const LEAVEROOM_FAIL = "LEAVEROOM_FAIL";
export const LEAVEROOM_SUCCESS = "LEAVEROOM_SUCCESS";


