import React, { useEffect, useState } from 'react';
import UseRouts from './routes';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import HeaderAuth from './components/Header/HeaderAuth';
import { socket } from './socket';
import { setRoomList } from './redux/actions/room';

function App() {
  const { auth } = useSelector((state) => state);
  const [ready, setReady] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);
  const [itemList, setItemList] = useState([]);
  const dispatch = useDispatch();
  

  useEffect(() => {
    function onConnect() {
      console.log('connected');
      setIsConnected(true);
    }
    function onDisconnect() {
      console.log('disconnected');
      setIsConnected(false);
    }
    function onFooEvent(value) {
      console.log('foo');
      console.log(value);
      setFooEvents((previous) => [...previous, value]);
    }
    function onItemList(data) {
      // console.log('Received item list:', data);
      dispatch(setRoomList(data));
      setItemList(data);
    }
    function onError(data){
      alert(data);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('foo', onFooEvent);
    socket.on('roomsList', onItemList);
    socket.on('error', onError);
    


    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('foo', onFooEvent);
    };
  }, []);

  useEffect(() => {
    if (!ready) {
      setReady(true);
    }
  }, []);

  useEffect(() => {
    if (auth.isLoggedIn ) {
      socket.emit('userLoggedIn', { isLoggedIn: auth.isLoggedIn, accessToken:auth.user.accessToken });
    } else {
      socket.emit('userLoggedOut', { isLoggedIn: auth.isLoggedIn });
    }
  }, [auth.isLoggedIn,isConnected]);

  return (
    <div className="App">
      {!auth.isLoggedIn && <Header isConnected={isConnected}/>}
      {auth.isLoggedIn && <HeaderAuth isConnected={isConnected}/>}
      <div className="main">
        {ready && <UseRouts />}
      </div>
      <Footer />
    </div>
  );
}

export default App;
