import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';

const register = (form) => {
  return axios.post(constants.URL + "auth/register", 
    {...form}
  ).then((response) => {
       
    return response;
  }).catch((e)=>{
          return e.response
  });
};

const login = (body) => {
  return axios
    .post(constants.URL + "auth/login", {
      ...body
    })
    .then((response) => {
      console.log(response);
      if (response.data.accessToken && response.data.refreshToken) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=>{
      
      return e.response.data;
    });
};
const registerCode=(body)=>{
  return axios
  .post(constants.URL + "auth/register-code",{...body})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return e.response.data;
  });
}
const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
};

const refresh=(refreshToken)=>{
  let data = {refreshToken:refreshToken}
  return axios.post(constants.URL + "auth/refresh",data,{headers: authHeader()})
  .then((response) => {
    if (response.data.accessToken && response.data.refreshToken) {
      localStorage.setItem("userData", JSON.stringify(response.data));
    }
    return response.data;
  }).catch((e)=>{
    
    console.log(e);
    logout();
  });;
}

export default {
  register,
  login,
  logout,
  registerCode,
  refresh
};






