import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../components/Icon";
import { login } from "../../redux/actions/auth";


const AuthPage=()=>{
    const [show,setShow]=useState(false)
    const { t } = useTranslation();
    const navigate=useNavigate();
    const [form,setForm]=useState({})
    const dispatch = useDispatch();
    const [error,setError]=useState(false)


    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
    };

    const loginHandler =(e)=>{
        dispatch(login(form)).then(res=>{
            if(res.error){
                setError(true);
            }else{
                navigate('/')
            }
        }).catch(e=>{

        })
    }

return(
    <div className="prFullHeight">
        <div className="prHeaderAuth"> {t("auth_login" )} </div>
        <div className="prInput">
            <label htmlFor="email" className="prInputLabel">{t("email" )}</label>
            <input onChange={changeHandler} id="email" type="text" className="prInputInput" placeholder={t("mail" )} />
        </div>
        <div className="prInput prInputIcon">
            <label htmlFor="password" className="prInputLabel">{t("password" )}</label>
            <input  onChange={changeHandler} id="password" type={show?'text':"password"}  className="prInputInput" placeholder={t("password" )} />
            <div className="prInputIconIcon" onClick={()=>setShow(!show)}><Icon type={show?'eyeOpen':'eyeClosed'}/></div>
        </div>

        {/* <div className="prFlexRow" style={{gap:'14px', marginTop:'41px',marginBottom:'35px'}}>
            <div className="pointer"><Icon type='google'></Icon></div>
            <div className="pointer"><Icon type='fbAuth'></Icon></div>
        </div> */}
        <button onClick={loginHandler} className="prButton prButtonMain" style={{marginBottom:'60px'}}>{t("auth" )}</button>
        <div className="prLineAuth"></div>
        <div className="prflexColumn">
            <div className="prLinkAuth"> {t("no_acc" )} <a onClick={()=>navigate('/registration')} className="link">{t("register" )}</a></div>
            <div className="prLinkAuth"> {t("forget_password" )} <a onClick={()=>navigate('/restore')}  className="link">{t("restore_password" )}</a></div>
        </div>
       
    </div>
)

}


export default AuthPage;