import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



const Page404 = ()=>{
    const navigate = useNavigate();
    const { t } = useTranslation();

    function handleClick() {
        navigate("/");
      }
    return(
    <div className="prFullHeight">
      <div className='page404'>404</div>
      <div className='page404Text'>{t("404_notFound")}</div>
      <div className=''><a href="#" className='link'>{t("404_main")}</a></div>
    </div>
    )

}

export default Page404;