import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createRoom } from "../../redux/actions/room";
import { Icon } from "../Icon";

const CreateModal = (props) => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const dispatch= useDispatch();
    const navigate= useNavigate()
    const [form, setForm] = useState({
        maxUsers: 13, // Default value for maxUsers
        balanceCount: 1, // Default value for balanceCount
        timeForTurn: 10, // Default value for timeForTurn
        isDefaultPistol: 0, // Default value for isDefaultPistol
        chatRequest: 1, // Default value for chatRequest
        isPrivate: 0 // Default value for isPrivate
    });
    const onClose = () => {
        props.setOpen(false);
    };

    const createClick = () => {
        dispatch(createRoom(form)).then(res=>{
            navigate('/room/'+res.room.id);
        }).catch(e=>{

        })
    };

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
    };

    const changeSelected = (event) => {
        let [data, value] = event.target.id.split('/');
        setForm({ ...form, [data]: +value });
    };

    return (
        <>
            <div className="modalOverlay" onClick={onClose}></div>
            <div className="createModal prflexColumn">
                <div className="prFlexRow">
                    <div className="prHeaderAuth">{t("create_room")}</div>
                    <div className="prButton prButtonMain" onClick={onClose}>&times;</div>
                </div>
                
                <div>
                    <div>{t("users")}</div>
                    <div className="prFlexRow">
                        {['13', '11', '9', '7', '5', '3', '2'].map(value => (
                            <div
                                key={`maxUsers/${value}`}
                                id={`maxUsers/${value}`}
                                className={form['maxUsers'] == value ? "checkRoundButton checked" : "checkRoundButton"}
                                onClick={changeSelected}
                            >
                                {value}
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <div>{t("balance_count")}</div>
                    <div className="prFlexRow">
                        {['1', '2', '5', '10', '50', '100', '500'].map(value => (
                            <div
                                key={`balanceCount/${value}`}
                                id={`balanceCount/${value}`}
                                className={form['balanceCount'] == value ? "checkRoundButton checked" : "checkRoundButton"}
                                onClick={changeSelected}
                            >
                                {value+'$'}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="prFlexRow">
                    <div className="">
                        <div>{t("time_for_turn")}</div>
                            <div className="prFlexRow">
                                {['5', '10', '30'].map(value => (
                                    <div
                                        key={`timeForTurn/${value}`}
                                        id={`timeForTurn/${value}`}
                                        className={form['timeForTurn'] == value ? "checkRoundButton checked" : "checkRoundButton"}
                                        onClick={changeSelected}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="createItem">
                            <div>{t("is_default_pistol")}</div>
                            <div className="prFlexRow">
                                {['1', '0'].map(value => (
                                    <div
                                        key={`isDefaultPistol/${value}`}
                                        id={`isDefaultPistol/${value}`}
                                        className={form['isDefaultPistol'] == value ? "checkRoundButton checked" : "checkRoundButton"}
                                        onClick={changeSelected}
                                    >
                                        {value==1?t("yes"):t("no")}
                                    </div>
                                ))}
                                </div>
                            </div>
                    <div className="">
                        <div>{t("chat_request")}</div>
                            <div className="prFlexRow">
                                {['1', '0'].map(value => (
                                    <div
                                        key={`chatRequest/${value}`}
                                        id={`chatRequest/${value}`}
                                        className={form['chatRequest'] == value ? "checkRoundButton checked" : "checkRoundButton"}
                                        onClick={changeSelected}
                                    >
                                         {value==1?t("yes"):t("no")}

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                <div>
                    <div>{t("is_private")}</div>
                    <div className="prFlexRow">
                        {['1', '0'].map(value => (
                            <div
                                key={`isPrivate/${value}`}
                                id={`isPrivate/${value}`}
                                className={form['isPrivate'] == value ? "checkRoundButton checked" : "checkRoundButton"}
                                onClick={changeSelected}
                            >
                                {value==1?t("yes"):t("no")}
                            </div>
                        ))}
                    </div>
                </div>
                {form['isPrivate']==1 && <div className="prInput prInputIcon">
                    <label htmlFor="password" className="prInputLabel">{t("password")}</label>
                    <input
                        onChange={changeHandler}
                        id="password"
                        type={show ? 'text' : "password"}
                        className="prInputInput"
                        placeholder={t("password")}
                    />
                    <div className="prInputIconIcon" onClick={() => setShow(!show)}>
                        <Icon type={show ? 'eyeOpen' : 'eyeClosed'} />
                    </div>
                </div>}
                <div className="prFlexRow">
                    <button className="prButton prButtonMain" onClick={createClick}>{t("create")}</button>
                    <button className="prButton prButtonMain" onClick={onClose}>{t("close")}</button>
                </div>
            </div>
        </>
    );
};

export default CreateModal;
