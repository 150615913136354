import { combineReducers } from "redux";
import auth from "./auth";
import room from "./room";
import currentRoom from "./currentRoom";


export default combineReducers({
  auth,
  room,
  currentRoom,
  });
