import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/actions/auth';
import { Icon } from '../Icon';


const HeaderAuth=(props)=>{
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const logOutClick=()=>{
        dispatch(logout())
    }
    return(
        <div className='headerPosition'>
            <div className='header prFlexRow'>
                <div className='headerLogo' onClick={()=>navigate('/')} >
                    <Icon type='logo'></Icon>
                    Bang Bang                
                </div>
                <div className='headerButtons'>
                    {props.isConnected && 
                    'Підʼєднані'}
                    <button className='prButton prButtonMain' onClick={logOutClick}>
                        LogOut
                    </button>
                    {/* <button className='prButton prButtonMain' onClick={()=>navigate('/login')} style={{marginRight:'17px'}}>
                        {t("auth_login" )}
                    </button>
                    <button className='prButton prButtonMain' onClick={()=>navigate('/registration')}>
                        {t("auth_registartion")}
                    </button> */}
                </div>
            </div>
        </div>
    )
};

export default HeaderAuth;