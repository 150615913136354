import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';
import { changeLanguage } from '../../i18n';


const Footer =()=>{
    const { t } = useTranslation();
    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        changeLanguage(selectedLanguage);
      };

    return(
        <div className='footerPositition'>
            <div className='footer'>
                <div className='prFlexRow footerTop'>
                    <div className='headerLogo'>
                        <Icon type='logo'></Icon>
                        Bang Bang
                    </div>
                    <div className='prFlexRow footerLinks'>
                        <Icon type='telegram' link='#'></Icon>
                        <Icon type='insta' link='#'></Icon>
                        <Icon type='fb' link='#'></Icon>
                        <Icon type='tikTok' link='#'></Icon>
                    </div>
                </div>
                <div className='prFlexRow footerBottom'>
                    <div>
                        {t("right_reserve")}
                    </div>
                    <div className='prFlexRow'>
                        <a href="#">{t("policy_confidential")}</a>
                        <a href="#" style={{margin:'0 30px'}}>{t("rules")}</a>
                        <a href="#">{t("Cookies")}</a>
                        <div>
                            <select className='langSelect' id="language-select" onChange={handleLanguageChange}>
                                <option value="ua">{t("Ukrainian")}</option>
                                <option value="en">{t("English")}</option>
                                <option value="ru">{t("Russian")}</option>
                                <option value="de">{t("German")}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


}

export default Footer;