import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../Icon';


const Header=(props)=>{
    const { t } = useTranslation();
    const navigate = useNavigate();


    return(
        <div className='headerPosition'>
            <div className='header prFlexRow'>
                <div className='headerLogo' onClick={()=>navigate('/')} >
                    <Icon type='logo'></Icon>
                    Bang Bang                
                </div>
                <div className='headerButtons'>
                    {props.isConnected && 
                    'Підʼєднані'}
                    <button className='prButton prButtonMain' onClick={()=>navigate('/login')} style={{marginRight:'17px'}}>
                        {t("auth_login" )}
                    </button>
                    <button className='prButton prButtonMain' onClick={()=>navigate('/registration')}>
                        {t("auth_registration")}
                    </button>
                </div>
            </div>
        </div>
    )
};

export default Header;